var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v(" 分销设置 ")]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分销商品开关" } },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.isDisabled,
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.ruleForm.onOff,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "onOff", $$v)
                  },
                  expression: "ruleForm.onOff",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "奖励条件" } }, [
            _vm._v(" 被分享人成功下单，分享人获得待结算佣金 "),
          ]),
          _c(
            "el-form-item",
            [
              !_vm.isDisabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(" 保存 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }