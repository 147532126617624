<template>
  <div class="outer">
    <h1 class="title">
      分销设置
    </h1>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="分销商品开关">
        <el-switch
          v-model="ruleForm.onOff"
          :disabled="isDisabled"
          :active-value="1"
          :inactive-value="0"
        />
      </el-form-item>
      <el-form-item label="奖励条件">
        被分享人成功下单，分享人获得待结算佣金
      </el-form-item>
      <!-- <el-form-item
          label="分销人员设置"
          prop="distributeScopType"
        >
          <el-radio-group v-model="ruleForm.distributeScopType">
            <el-radio :label="1">
              全员分销
            </el-radio>
            <el-radio :label="2">
              指定人员分销
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="mobil_numbers"
        >
          <el-input
            v-model="ruleForm.mobil_numbers"
            type="textarea"
            placeholder="手机号与手机号之间使用英文逗号隔开（例：18888888888,199999999999）"
            :rows="4"
          ></el-input>
        </el-form-item> -->

      <el-form-item>
        <el-button
          v-if="!isDisabled"
          type="primary"
          size="small"
          @click="submitForm('ruleForm')"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import goodsDialog from './components/goodsDialog.vue';
import { UserModule } from '@/store/modules/user';

export default {
  name: 'distribution-setting',
  components: { goodsDialog },
  data() {
    return {
      projectCode: localStorage.getItem('projectCode'),
      dayjs,
      dialogVisible: false,
      suppliersId: '',
      onOff: false,
      ruleForm: {
        distributeScopType: 1,
        mobil_numbers: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur',
          },
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' },
        ],
        date1: [
          {
            type: 'date', required: true, message: '请选择日期', trigger: 'change',
          },
        ],
      },

    };
  },
  computed: {
    isDisabled() {
      return this.projectCode === '5100';
    },
  },
  mounted() {
    this.suppliersId = JSON.parse(localStorage.getItem('userInfo')).suppliersId;
    this.getInfo();
  },
  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('确认要修改分销设置吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then((value) => {
            this.$axios({
              method: 'post',
              url: this.$api.DistributeController.updateDistributeConf,
              data: {
                distributeScopType: this.ruleForm.distributeScopType,
                goods: this.ruleForm.goods,
                mobil_numbers: this.ruleForm.mobil_numbers,
                onOff: this.ruleForm.onOff,
                suppliersId: this.suppliersId,
                id: this.ruleForm.id,
              },
            }).then((res) => {
              let r = res || {};
              if (r.code === 0) {
                this.$message({ type: 'success', message: '操作成功' });
              }
              if (r.error === 1) {
                this.$message({ type: 'error', message: r.msg });
              }
            });
          });
        } else {
          return false;
        }
      });
    },
    // 获取分销设置信息
    getInfo() {
      this.$axios({
        method: 'post',
        url: this.$api.DistributeController.selecDistributeConf,
        data: {
          suppliersId: Number(this.suppliersId),
        },
      }).then((res) => {
        if (res.code === 0) {
          this.ruleForm = res.data;
        }
      });
    },

  },
};
</script>
<style scoped>
.title{
  padding: 20px 0;
  font-weight: 600;
}
</style>
